import type { Ad, AdTariff } from '~/types'

interface StateCount {
  all: number
  moderate: number
  started: number
  paused: number
  stopped: number
}

interface State {
  counts: StateCount,
  countModerate: number
  tariffs?: AdTariff[]
}

export const useAdStore = defineStore('ad', {
  state: (): State => {
    return <State>{
      counts: {
        all: 0,
        moderate: 0,
        started: 0,
        paused: 0,
        stopped: 0,
      },
      countModerate: 0,
      tariffs: []
    }
  },
  actions: {
    async init() {
      const result = await useAPIFetch<{ default: string, items: AdTariff[] }>('/v1/ads/tariffs')

      this.tariffs = result.items
    },
    loadCounts (query: any = {}) {
      useAPIFetch<StateCount>('/v1/ads/admins/counts', {
        method: 'GET',
        query,
      }).then(data => {
        this.counts = data
      })
    },
    loadCountModerate () {
      useAPIFetch<StateCount>('/v1/ads/admins/counts', {
        method: 'GET'
      }).then(data => {
        this.countModerate = data.moderate
      })
    },
    show(id: string) {
      return useAPIFetch<Ad>('/v1/ads/' + id, {})
    },
    create(data: any) {
      return useAPIFetch('/v1/ads', {
        method: 'POST',
        body: data,
      })
    },
    update(id: string, data: any) {
      return useAPIFetch('/v1/ads/' + id, {
        method: 'POST',
        body: data,
      })
    },
    delete(id: string) {
      return useAPIFetch('/v1/ads/' + id, {
        method: 'DELETE',
      })
    },
  }
})
